import FooterBottom from '~/themes/base/components/shop/FooterBottom'

export default {
  extends: FooterBottom,
  data() {
    return {
      paymentMethods:
        this.$themeSettings.components.FooterBottom.paymentMethods.items &&
        this.$themeSettings.components.FooterBottom.paymentMethods.items.length > 0
          ? this.$themeSettings.components.FooterBottom.paymentMethods.items
          : [
              'mastercard',
              'mastercard-electronic',
              'maestro',
              'visa-electron',
              'visa-electron-dark',
              'google-pay',
              'apple-pay',
            ],
      paymentIcons: {
        'google-pay': require('@icon/payment/google-pay.svg'),
        'apple-pay': require('@icon/payment/apple-pay.svg'),
      },
      isVisible: false,
    }
  },
}
