import ContentBuilder from '@theme/components/utils/ContentBuilder'
import FooterBottomTopHook from '@theme/components/shop/FooterBottomTopHook'
import ScrollToEventMixin from '~/mixins/ScrollToEventMixin'
import SocialNetworkLinks from '@theme/components/content/SocialNetworkLinks'

export default {
  components: {
    ContentBuilder,
    FooterBottomTopHook,
    FooterContact: () => import('@theme/components/shop/FooterContact'),
    SocialNetworkLinks,
  },
  mixins: [ScrollToEventMixin],
  data() {
    return {
      paymentMethods:
        this.$themeSettings.components.FooterBottom.paymentMethods.items &&
        this.$themeSettings.components.FooterBottom.paymentMethods.items.length > 0
          ? this.$themeSettings.components.FooterBottom.paymentMethods.items
          : ['mastercard', 'mastercard-electronic', 'maestro', 'visa-electron', 'visa-electron-dark'],
      paymentIcons: {
        gopay: require('@icon/payment/gopay.svg'),
        maestro: require('@icon/payment/maestro.svg'),
        mastercard: require('@icon/payment/mastercard.svg'),
        'mastercard-electronic': require('@icon/payment/mastercard-electronic.svg'),
        'mastercard-secure-code': require('@icon/payment/mastercard-secure-code.svg'),
        'verified-by-visa': require('@icon/payment/verified-by-visa.svg'),
        visa: require('@icon/payment/visa.svg'),
        'visa-electron': require('@icon/payment/visa-electron.svg'),
        'visa-electron-dark': require('@icon/payment/visa-electron-dark.svg'),
      },
      isVisible: false,
    }
  },
  beforeMount() {
    if (this.$themeSettings.components.FooterBottom.showCopyright) {
      this.$on('scrolledTo', () => {
        import('@theme/assets/scss/inspirum-font.scss')
        this.isVisible = true
      })
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
  },
}
